export const ProvideList = ({ title, imageSrc, items = [], mainImage }) => (
  <section className="bg-gray-background">
    <div className="w-11/12 lg:w-3/4 mx-auto  flex flex-col items-center">
      <h1 className="font-montserrat font-black text-5xl  text-center text-blue-leadlab mb-10">
        {title}
      </h1>

      <div className="flex flex-col lg:flex-row items-stretch gap-10 justify-center text-xl">
        <img src={mainImage} alt={title} className="lg:w-1/2 object-cover" />
        <ul className="flex flex-col gap-2 lg:w-1/2 font-montserrat ">
          {items.map(({ id, text }) => (
            <li key={id} className="flex flex-row gap-10 py-5 items-center">
              <img
                src={imageSrc}
                alt={title}
                className="w-1/6 sm:w-1/12 md:w-1/12 lg:w-1/12 object-cover"
              />
              {text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </section>
);
