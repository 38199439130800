/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const leadLabsEmail = process.env.REACT_APP_EMAIL;
const ContactUsForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [question1, setQuestion1] = useState('');
  const [question2, setQuestion2] = useState('');
  const [question3, setQuestion3] = useState('');
  const [question4, setQuestion4] = useState('');
  const [question5, setQuestion5] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const functions = getFunctions();
  useEffect(() => {
    if (submitted) {
      // Reset the form fields to their initial values
      setFirstName('');
      setLastName('');
      setEmail('');
      setCompanyName('');
      setPhone('');
      setQuestion1('');
      setQuestion2('');
      setQuestion3('');
      setQuestion4('');
      setQuestion5('');
      setSubmitted(false); // Reset the submitted state
    }
  }, [submitted]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const questionMapping = {
      question1: 'How do you generate leads?',
      question2: 'Current lead volumes',
      question3: 'Do you buy or sell leads?',
      question4: 'How did you hear about us?',
      question5: 'Tell us about you current needs',
    };

    const formData = {
      firstName,
      lastName,
      email,
      companyName,
      phone,
      question1,
      question2,
      question3,
      question4,
      question5,
    };
    const formDataString = Object.entries(formData)
      .map(([key, value]) => `${questionMapping[key] || key}: ${value}`)
      .join('\n');

    console.log(formData);

    addDoc(collection(db, 'customers'), formData)
      .then((docRef) => {
        console.log('Document written:', docRef.id);
        const sendEmail = httpsCallable(functions, 'sendEmail');
        sendEmail({
          to: leadLabsEmail,
          subject: 'Contact Form - Lead Lab',
          text: `You are receiving this automated email because the contact form on lead-lab.digital was completed. \n\n${formDataString}`,
        })
          .then((result) => {
            console.log(result.data); // Output from your function
          })
          .catch((error) => {
            console.error('Error:', error);
          });
        setSubmitted(true);
      })
      .catch((e) => {
        console.error('Error adding document: ', e);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="flex flex-col p-4">
        <div className="flex flex-row">
          <div className="flex flex-col w-1/2 pr-2">
            <label className="m-2">
              <input
                type="text"
                name="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                required
                className="p-2 border m-1 w-full rounded-md"
                placeholder="First Name"
              />
            </label>
            <label className="m-2">
              <input
                type="text"
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
                required
                className="p-2 border m-1 w-full rounded-md"
                placeholder="Last Name"
              />
            </label>
            <label className="m-2">
              <input
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                className="p-2 border m-1 w-full rounded-md"
                placeholder="Email"
              />
            </label>
            <label className="m-2">
              <input
                type="tel"
                name="phone"
                onChange={(e) => setPhone(e.target.value)}
                required
                className="p-2 border m-1 w-full rounded-md"
                placeholder="Phone"
              />
            </label>
            <label className="m-2">
              <input
                type="text"
                name="companyName"
                onChange={(e) => setCompanyName(e.target.value)}
                required
                className="p-2 border m-1 w-full rounded-md"
                placeholder="Company name"
              />
            </label>
          </div>
          <div className="flex flex-col w-1/2 pl-2">
            <label className="m-2">
              <select
                name="question1"
                onChange={(e) => setQuestion1(e.target.value)}
                required
                className="p-2 border m-1 w-full rounded-md"
                placeholder="How do you generate leads?"
                style={{ color: question1 === '' ? '#aaa' : '#aaa' }}
              >
                <option value="" disabled selected>
                  How do you generate leads?
                </option>
                {[
                  'Social',
                  'Paid',
                  'Native',
                  'Affiliate',
                  'Other',
                  'Combination',
                  'I do not generate leads',
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
            <label className="m-2">
              <select
                name="question1"
                onChange={(e) => setQuestion2(e.target.value)}
                required
                className="p-2 border m-1 w-full rounded-md"
                placeholder="Current lead volumes"
                style={{ color: question1 === '' ? '#aaa' : '#aaa' }}
              >
                <option value="" disabled selected>
                  Current lead volumes
                </option>
                {[
                  '100 or less p/week',
                  '100-200 p/week',
                  '200-500 p/week',
                  '1000+ p/week',
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
            <label className="m-2">
              <select
                name="question1"
                onChange={(e) => setQuestion3(e.target.value)}
                required
                className="p-2 border m-1 w-full rounded-md"
                placeholder="Do you buy or sell leads?"
                style={{ color: question1 === '' ? '#aaa' : '#aaa' }}
              >
                <option value="" disabled selected>
                  Do you buy or sell leads?
                </option>
                {['Buy', 'Sell', 'Neither', 'Both'].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
            <label className="m-2">
              <select
                name="question1"
                onChange={(e) => setQuestion4(e.target.value)}
                required
                className="p-2 border m-1 w-full rounded-md"
                placeholder="How did you hear about us?"
                style={{ color: question1 === '' ? '#aaa' : '#aaa' }}
              >
                <option value="" disabled selected className="text-gray-400">
                  How did you hear about us?
                </option>
                {[
                  'Event I attended',
                  'Company using you',
                  'Facebook/Instagram Ad',
                  'Facebook Group',
                  'Google',
                  'Review Center',
                  'LinkedIn Post',
                  'Online Forum',
                  'Social media advert',
                  'Other',
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
            <label className="m-2">
              <textarea
                name="question5"
                onChange={(e) => setQuestion5(e.target.value)}
                required
                className="p-2 border m-1 w-full h-20 rounded-md "
                placeholder=" Tell us about you current needs"
              />
            </label>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="p-2 mt-4 bg-blue-500 text-white rounded rounded-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactUsForm;
