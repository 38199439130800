import Logo from '../../../assets/images/Lead lab_horizontal white logo.png';

export const Footer = () => (
  <div className="bg-blue-leadlab p-10">
    <div className="lg:ml-20 lg:w-72">
      <img
        src={Logo}
        alt="Logo"
        className="lg:ml-0 w-60 h-auto object-contain mb-3"
      />
      <p className="text-white text-left">
        Phone: <strong>8002729023</strong>
      </p>
      <p className="text-white text-left">
        E-mail: <strong>talk@lead-lab.digital</strong>
      </p>
      <p className="text-white text-left">
        Addres: <strong>2300 SE Monterey Rd, Stuart Fl. 34996 Suite 101</strong>
      </p>
    </div>
  </div>
);
