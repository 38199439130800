export const DoubleList = ({ title, imageSrc, items = [] }) => (
  <div className="w-11/12 lg:w-3/4 mx-auto my-5 ">
    <h1 className="font-montserrat font-black text-5xl text-center text-blue-leadlab mb-5">
      {title}
    </h1>
    <div className="flex flex-col lg:flex-row-reverse items-stretch gap-10 ">
      <img src={imageSrc} alt={title} className="lg:w-1/2 object-cover" />
      <div>
        <h2 className="font-montserrat font-bold text-center md:text-left text-green-leadlab">
          Establish B2B or B2C campaigns as exclusive or shared
        </h2>
        <div className="doubleListContainer px-1 flex flex-row gap-5 text-xl place-content-around font-light lg:w-1/2 ">
          <ul className="py-10 font-montserrat break-words">
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> Personal Lines Insurance
            </li>
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> Home Services
            </li>
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> Legal
            </li>
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> Personal Banking/ Finance
            </li>
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> HealthCare/ Dental
            </li>
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> Retail
            </li>
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> Training{' '}
            </li>
          </ul>
          <ul className="py-10 font-montserrat break-words">
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> Hospitality/ Tourism
            </li>
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> Automotive
            </li>
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> Medical
            </li>
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> Consultation
            </li>
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> Telecommunications
            </li>
            <li className="py-1 flex flex-row gap-1 ">
              <p className="text-blue-leadlab">■</p> Real estate
            </li>
            <li className="py-1 flex flex-row gap-1 gap-1 ">
              <p className="text-blue-leadlab">■</p> Logistics
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);
