const getStyleProps = (index) =>
  index % 2 !== 0
    ? {
        flexRowDirection: 'lg:flex-row',
        textAlignment: 'lg:text-left',
        titleColor: 'text-blue-leadlab',
        backgroundColor: 'bg-white',
      }
    : {
        flexRowDirection: 'lg:flex-row-reverse',
        textAlignment: 'lg:text-right',
        titleColor: 'text-blue-leadlab',
        backgroundColor: 'bg-gray-background',
      };

export const SectionWithVideo = ({
  complementaryText,
  descriptions = [],
  imageSrc,
  subtitle,
  title,
  index = 0,
}) => {
  const { flexRowDirection, textAlignment, titleColor, backgroundColor } =
    getStyleProps(index);

  return (
    <section className={backgroundColor}>
      <div
        className={`w-11/12 lg:w-3/4 mx-auto py-20 flex flex-col items-stretch gap-10 ${flexRowDirection}`}
      >
        <video
          src={imageSrc}
          autoPlay
          loop
          muted
          className="lg:w-1/2 object-cover"
        />
        <div className={`lg:w-1/2 ${textAlignment}`}>
          <h2
            className={` font-montserrat font-extrabold text-4xl ${titleColor}`}
          >
            {title}
          </h2>
          <h3 className="mb-5 mt-2 text-blue-leadlab font-montserrat font-extrabold text-4xl">
            {subtitle}
          </h3>
          <div className="flex flex-col gap-3">
            {descriptions.map(({ id: textId, text }) => (
              <p
                key={textId}
                className="font-montserrat text-gray-leadlabfont text-xl"
              >
                {text}
              </p>
            ))}
          </div>
          {complementaryText && (
            <p className="mt-3 font-light">{complementaryText}</p>
          )}
        </div>
      </div>
    </section>
  );
};
