export const ROUTES = {
  HOME: '/',
  ABOUT: '/about',
  SERVICES: '/services',
  CONTACT: '/contact',
};

export const ROUTE_NAMES = {
  HOME: 'Home',
  ABOUT: 'Us',
  SERVICES: 'Services',
  CONTACT: 'Contact Us',
};
