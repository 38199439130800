/* eslint-disable import/no-extraneous-dependencies */
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyD0VBS6SD3UHuDGsWWcA5fIwJ_MlZFIB1U',
  authDomain: 'leadlabsdatabase.firebaseapp.com',
  projectId: 'leadlabsdatabase',
  storageBucket: 'leadlabsdatabase.appspot.com',
  messagingSenderId: '247650483629',
  appId: '1:247650483629:web:a2b04ddabf9fcb0a027524',
};
if (getApps().length === 0) {
  initializeApp(firebaseConfig);
}
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db };
