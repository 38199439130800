import { MainLayout } from '../components/layouts/MainLayout';
import ContactUsForm from '../components/contactUsForm/ContactUsForm';

export const ContactUsPage = ({
  shouldHaveTitle = true,
  alwaysVertical = false,
}) => (
  <MainLayout>
    <div className="w-11/12 lg:w-3/4 mx-auto my-10 flex flex-col md:flex-row gap-12 items-center justify-center">
      <div className="w-full">
        {shouldHaveTitle && (
          <h1 className="text-4xl  text-blue-500 mb-4 font-montserrat font-bold">
            Contact Us
          </h1>
        )}
        <ContactUsForm />
      </div>
    </div>
  </MainLayout>
);
