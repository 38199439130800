const videoAsset = 'https://i.imgur.com/qVztcox.mp4';
export const HOME_DATA = [
  {
    id: 1,
    imageSrc: videoAsset,
    title: 'Comprehensive',
    subtitle: 'Data Solutions',
    descriptions: [
      {
        id: 1,
        text: 'Finally, the tools to source qualified leads for your business. ',
      },
      {
        id: 2,
        text: 'Useful for companies looking to scale outbound sales and marketing processes efficiently. ',
      },
      {
        id: 3,
        text: 'Don’t rely on lists of phone numbers for consumers who are not interested. Instead, connect with interested consumers when they express interest in your specific product or service.',
      },
    ],
  },
];
