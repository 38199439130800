import React from 'react';
import { NavLink } from 'react-router-dom';
import { NAVBAR_LINKS } from '../../../constants/navbar';

export const NavLinks = () =>
  NAVBAR_LINKS.map(({ to, name }) => (
    <NavLink
      key={to}
      to={to}
      className={({ isActive }) =>
        `${
          isActive ? 'text-blue-leadlab' : 'text-gray-leadlabfont'
        } uppercase text-center px-5 py-2 rounded-md font-bold text-lg duration-300 hover:opacity-75`
      }
    >
      {name}
    </NavLink>
  ));
