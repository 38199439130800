export const Button = ({
  children,
  isSubmitButton = false,
  className = '',
  onClick = () => {},
}) => (
  <button
    type={isSubmitButton ? 'submit' : 'button'}
    className={`text-white bg-blue-leadlab p-2 rounded-md font-bold duration-300 hover:opacity-75 ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
);
