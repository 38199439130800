export const SERVICES_DATA = [
  {
    id: 1,
    content:
      'Best practices for connecting with high purchase intent consumers.',
  },
  {
    id: 2,
    content:
      'Contact information and, in some cases, demographic information of a customer interested in a specific product or service.',
  },
  {
    id: 3,
    content:
      "Initiation of consumer interest or inquiry and connections with your business's products or services.",
  },
];

export const SERVICES_LIST_DATA = [
  {
    id: 1,
    text: 'A central command center where you can monitor, analyze, and easily decide to take action.',
  },
  {
    id: 2,
    text: 'Become Proactive instead of Reactive.',
  },
  {
    id: 3,
    text: 'Seamlessly go from insight to decision, to action, without wasting unnecessary time.',
  },
];
