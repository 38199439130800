import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ROUTES } from '../constants/routes';
import { AboutUsPage } from '../pages/AboutUsPage';
import { ContactUsPage } from '../pages/ContactUsPage';
import { HomePage } from '../pages/HomePage';
import { NotFoundPage } from '../pages/NotFoundPage';
import { ServicesPage } from '../pages/ServicesPage';

const router = createBrowserRouter([
  {
    path: ROUTES.HOME,
    element: <HomePage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: ROUTES.SERVICES,
    element: <ServicesPage />,
  },
  {
    path: ROUTES.CONTACT,
    element: <ContactUsPage />,
  },
]);

export const AppRouter = () => <RouterProvider router={router} />;
