import { Footer } from '../common/Footer/Footer';
import { Header } from '../common/Header/Header';

export const MainLayout = ({ children }, bgColor) => (
  <>
    <Header bgColor={bgColor} />
    <div className="mx-auto font-montserrat">{children}</div>
    <Footer />
  </>
);
