export const LIST_DATA = [
  {
    id: 1,
    text: 'Business-to-consumer campaigns as exclusive or shared',
  },
  {
    id: 2,
    text: 'Contact information and, in some cases, demographic information of a customer interested in a specific product or service.',
  },
  {
    id: 3,
    text: "Initiation of consumer interest or inquiry and connections with your business's products or services.",
  },
];
