import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { Button } from '../Button/Button';
import { DesktopNavbar } from '../Navbars/DesktopNavbar';
import { MobileNavbar } from '../Navbars/MobileNavbar';
import headerLogo from '../../../assets/images/Lead Lab svg 2.svg';

export const Header = () => (
  <header
    className={`w-full mx-auto py-5 flex flex-col lg:flex-row gap-5 items-center justify-between bg-gray-background px-10 `}
  >
    <div className="w-full lg:w-1/4">
      <Link to={ROUTES.HOME}>
        <img
          src={headerLogo}
          alt="Logo"
          className="mx-auto lg:ml-0 w-60 h-auto object-contain"
        />
      </Link>
    </div>
    <DesktopNavbar />
    <div className="flex justify-end lg:w-1/4">
      <a href="https://leadlab.px.com/login" target="_blank" rel="noreferrer">
        <Button className="text-sm px-10">LOG IN</Button>
      </a>
    </div>
    <MobileNavbar />
  </header>
);
