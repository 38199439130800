import { ROUTES, ROUTE_NAMES } from './routes';

export const NAVBAR_LINKS = [
  {
    to: ROUTES.HOME,
    name: ROUTE_NAMES.HOME,
  },
  {
    to: ROUTES.SERVICES,
    name: ROUTE_NAMES.SERVICES,
  },
  {
    to: ROUTES.CONTACT,
    name: ROUTE_NAMES.CONTACT,
  },
];
