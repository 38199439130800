import ContactUsForm from '../components/contactUsForm/ContactUsForm';
import { MainLayout } from '../components/layouts/MainLayout';
import { SectionWithImage } from '../components/common/Sections/SectionWithImage';
import { HOME_DATA } from '../data/home';
import { Video } from '../components/common/Video/Video';
import { SectionWithVideo } from '../components/common/Sections/SectionWithVideo';

const videoSource = 'https://i.imgur.com/qVztcox.mp4';
export const HomePage = () => (
  <MainLayout>
    {HOME_DATA.map((sectionData, idx) => (
      <SectionWithVideo key={sectionData.id} index={idx} {...sectionData}>
        {sectionData.id}
      </SectionWithVideo>
    ))}
    <div className="mx-auto w-11/12 md:w-2/3">
      <ContactUsForm />
    </div>
  </MainLayout>
);
