import ContactUsForm from '../components/contactUsForm/ContactUsForm';
import { MainLayout } from '../components/layouts/MainLayout';
import { SERVICES_DATA, SERVICES_LIST_DATA } from '../data/services';
import { ListSectionWithImage } from '../components/common/Sections/ListSectionWithImage';
import { ProvideList } from '../components/common/Sections/leadProvides';

import { LIST_DATA } from '../data/listData';
import { DoubleList } from '../components/common/Sections/doubleList';

const checkmarkurl = 'https://i.imgur.com/quIhArK.png';

const service3 = 'https://i.imgur.com/W7gfPoB.png';
const service4 = 'https://i.imgur.com/sndqwRl.png';
const service5 = 'https://i.imgur.com/TRHFZ9i.png';

export const ServicesPage = () => (
  <MainLayout bgColor="bg-gray-background">
    <ProvideList
      title="Lead Lab Provides"
      imageSrc={checkmarkurl}
      items={LIST_DATA}
      mainImage={service3}
    />
    <DoubleList
      title="Industries"
      imageSrc={service4}
      items={SERVICES_LIST_DATA}
    />
    <ListSectionWithImage
      title="Platform"
      imageSrc={service5}
      items={SERVICES_LIST_DATA}
    />
    <div className="mx-auto w-11/12 md:w-2/3">
      <ContactUsForm />
    </div>
  </MainLayout>
);
