import { Link } from 'react-router-dom';
import { Button } from '../components/common/Button/Button';
import { ROUTES } from '../constants/routes';
import Logo from '../assets/images/logo.png';

export const NotFoundPage = () => (
  <div className="h-screen w-screen flex justify-center items-center">
    <div className="flex flex-col items-center gap-3">
      <img src={Logo} alt="Logo" className="w-full h-10 lg:h-20 object-cover" />
      <h1 className="font-bold text-3xl">404: Page not found</h1>
      <Link to={ROUTES.HOME}>
        <Button>Go to Home</Button>
      </Link>
    </div>
  </div>
);
