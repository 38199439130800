export const ListSectionWithImage = ({ title, imageSrc, items = [] }) => (
  <section className="bg-gray-background">
    <div className="w-11/12 lg:w-3/4 mx-auto my-20">
      <h1 className="font-montserrat font-black  text-5xl text-center text-blue-leadlab mb-10 py-5">
        {title}
      </h1>
      <div className="flex flex-col lg:flex-row items-stretch gap-10 text-xl">
        <img src={imageSrc} alt={title} className="lg:w-1/2 object-cover" />
        <ul className="flex flex-col lg:w-1/2 gap-1 ">
          {items.map(({ id, text }) => (
            <li key={id} className="flex flex-col ">
              <div className="flex flex-row gap-1">
                <p className="text-blue-leadlab">■</p>
                {text}
              </div>
            </li>
          ))}
          <ul className=" font-montserrat gap-1  ">
            <li className="flex flex-row gap-1">
              <p className="text-blue-leadlab">■</p> Attribute end-to-end
              performance in one seamless view
            </li>
            <li className="flex flex-row gap-1">
              <p className="text-blue-leadlab">■</p> Manage Budget Allocations
              across all of your sources
            </li>
            <li className="flex flex-row gap-1">
              <p className="text-blue-leadlab">■</p> Identify performance trends
              at source/sub-id
            </li>
            <li className="flex flex-row gap-1">
              <p className="text-blue-leadlab">■</p> Daily schedule
            </li>
            <li className="flex flex-row gap-1">
              <p className="text-blue-leadlab">■</p> Integrated with client
              relationship management systems
            </li>
            <li className="flex flex-row gap-1">
              <p className="text-blue-leadlab">■</p> CPA reporting
            </li>
          </ul>
        </ul>
      </div>
    </div>
  </section>
);
